export const WORDS = [
'abbot',
'abort',
'adept',
'afasi',
'affix',
'affär',
'afton',
'agape',
'agave',
'agens',
'agent',
'agoni',
'agrar',
'aioli',
'akryl',
'akter',
'aktie',
'aktör',
'alarm',
'alban',
'album',
'alibi',
'alkov',
'alrot',
'altan',
'alumn',
'ambra',
'ampel',
'amöba',
'anbud',
'andel',
'anemi',
'angel',
'anime',
'aning',
'ankar',
'ankel',
'anlag',
'anlöp',
'annex',
'anomi',
'anrop',
'antal',
'antik',
'aorta',
'apati',
'april',
'aptit',
'areal',
'arena',
'argon',
'argot',
'arian',
'arier',
'arkad',
'arkiv',
'arrak',
'arsle',
'artär',
'asgam',
'asiat',
'astma',
'ataxi',
'atlas',
'atlet',
'atoll',
'atoni',
'atopi',
'attan',
'atypi',
'avans',
'avart',
'avbön',
'aveny',
'avgas',
'avgud',
'aviga',
'avisa',
'avkok',
'avlat',
'avrad',
'avrop',
'avsky',
'avtal',
'avtåg',
'avund',
'avväg',
'axill',
'axiom',
'backa',
'backe',
'bacon',
'bagel',
'bagge',
'bagis',
'bahai',
'balja',
'balle',
'balsa',
'bamba',
'bambu',
'bamse',
'banan',
'bandy',
'bandå',
'banjo',
'baron',
'baryt',
'basar',
'basis',
'basse',
'bastu',
'basun',
'basår',
'beach',
'bebis',
'befäl',
'begär',
'behag',
'behov',
'beige',
'belag',
'beröm',
'beska',
'besök',
'betyg',
'bevis',
'bevåg',
'bibel',
'bimbo',
'binda',
'bingo',
'birma',
'bison',
'bivax',
'björk',
'björn',
'black',
'blask',
'blast',
'bleck',
'blick',
'bling',
'blipp',
'blixt',
'block',
'blogg',
'bloss',
'blues',
'bluff',
'blygd',
'blåst',
'blått',
'bläck',
'blänk',
'blöja',
'boett',
'bogey',
'bohag',
'bolag',
'bolid',
'bonad',
'bonde',
'bonus',
'borst',
'bragd',
'brand',
'brant',
'brasa',
'brass',
'bredd',
'brigg',
'brink',
'brist',
'brits',
'brodd',
'broms',
'brons',
'brosk',
'brott',
'brugd',
'brunn',
'brunt',
'brygd',
'bryne',
'bråck',
'bråka',
'bråte',
'bräda',
'brädd',
'bröst',
'bulle',
'bunke',
'burka',
'buske',
'butan',
'butik',
'bygel',
'bygge',
'bytta',
'bälte',
'bärsa',
'bävan',
'bäver',
'bödel',
'bölja',
'börda',
'bössa',
'bövel',
'ceder',
'chans',
'chark',
'charm',
'chock',
'cider',
'citat',
'cykel',
'kräpp',
'dadel',
'dager',
'dagis',
'dansk',
'dativ',
'dator',
'datum',
'debut',
'dekal',
'dekan',
'dekor',
'demon',
'derby	',
'dildo',
'dimma',
'disko',
'djinn',
'docka',
'dolme',
'domän',
'donna',
'drake',
'drift',
'drott',
'druid',
'druva',
'dryck',
'drägg',
'dräkt',
'dräng',
'duell',
'duett',
'dugga',
'dunge',
'dunst',
'dusch',
'dvala',
'dvärg',
'dynga',
'dåtid',
'dänga',
'döing',
'ejder',
'ekfat',
'eksem',
'eloge',
'emalj',
'enbär',
'enhet',
'enkät',
'enris',
'enzym',
'estet',
'etapp',
'fabel',
'facit',
'fadäs',
'faner',
'farao',
'farsa',
'fasad',
'fasen',
'fasta',
'fatöl',
'fauna',
'favör',
'feber',
'fegis',
'femma',
'ferie',
'fetma',
'fiber',
'ficka',
'figur',
'fikon',
'fikus',
'filur',
'final',
'finka',
'finne',
'firma',
'fiske',
'fjant',
'fjord',
'fjäll',
'fjärt',
'fjäsk',
'flagg',
'flaje',
'flake',
'flams',
'flank',
'flarn',
'fling',
'flint',
'flisa',
'flock',
'flopp',
'flora',
'flott',
'fluff',
'fluga',
'fluor',
'fluss',
'flykt',
'flytt',
'flått',
'fläck',
'fläkt',
'fläsk',
'flöde',
'flöjt',
'flört',
'flöte',
'fniss',
'foder',
'fogde',
'fokus',
'folie',
'forma',
'foton',
'frack',
'frakt',
'frans',
'frist',
'front',
'frost',
'fruga',
'frukt',
'fråga',
'fräta',
'fröjd',
'fylla',
'fysik',
'fågel',
'fålla',
'fånge',
'fäbod',
'fälla',
'färja',
'fäste',
'följd',
'följe',
'galax',
'galge',
'galla',
'gasol',
'gavel',
'gegga',
'geist',
'gemak',
'gemen',
'gemål',
'genie',
'genom',
'genre',
'genus',
'getto',
'gevär',
'gifte',
'gille',
'gjord',
'glada',
'glans',
'glass',
'glimt',
'glipa',
'glosa',
'glugg',
'glänt',
'glögg',
'gnist',
'gnägg',
'gnäll',
'godis',
'gosse',
'graal',
'grabb',
'grann',
'grepp',
'greve',
'grift',
'grill',
'grind',
'groda',
'grodd',
'grogg',
'grund',
'grupp',
'gruva',
'grymt',
'gryta',
'gråbo',
'grått',
'gränd',
'gräns',
'gröda',
'grönt',
'gubbe',
'gudom',
'guide',
'gumma',
'gummi',
'gumse',
'gunga',
'gunst',
'gurka',
'gympa',
'gädda',
'gärde',
'gömma',
'hacka',
'hagel',
'halka',
'hallå',
'halva',
'hampa',
'harpa',
'havre',
'heder',
'hekto',
'herde',
'herre',
'hetta',
'hicka',
'hijab',
'hindu',
'hinna',
'hjord',
'hjort',
'hjälm',
'hjälp',
'hobby',
'holme',
'hoppa',
'hosta',
'humla',
'humle',
'humor',
'humus',
'humör',
'hurra',
'husar',
'husse',
'huvud',
'hydda',
'hyena',
'hygge',
'hylla',
'hylsa',
'hyvel',
'häfte',
'häkte',
'hälsa',
'hämnd',
'härad',
'härva',
'höger',
'hölje',
'hörna',
'ideal',
'idiom',
'idiot',
'idyll',
'igloo',
'iller',
'ilska',
'imrör',
'index',
'indie',
'inköp',
'insjö',
'insyn',
'intag',
'intro',
'intyg',
'inuit',
'ironi',
'isbit',
'islam',
'ister',
'ivran',
'jacka',
'jenka',
'jihad',
'jippo',
'joker',
'jolle',
'jubel',
'juice',
'jumbo',
'junta',
'juvel',
'jycke',
'jämna',
'jämte',
'jätte',
'jävel',
'jösse',
'kabel',
'kabin',
'kaffe',
'kagge',
'kajak',
'kakao',
'kakel',
'kalas',
'kalif',
'kamel',
'kamin',
'kanal',
'kanel',
'kanin',
'kanna',
'kanon',
'kanot',
'kanyl',
'kappa',
'karat',
'karma',
'karta',
'kassa',
'kasse',
'kavaj',
'kavel',
'kebab',
'kedja',
'kefir',
'kendo',
'kille',
'kines',
'kiosk',
'kippa',
'kisse',
'kista',
'klack',
'kladd',
'klaff',
'klant',
'klapp',
'klase',
'klass',
'klave',
'klibb',
'klick',
'klimp',
'klint',
'klipp',
'klirr',
'kloak',
'kloss',
'klove',
'klubb',
'kluck',
'kludd',
'klump',
'klunk',
'klåda',
'klämt',
'knack',
'knall',
'knapp',
'knark',
'knarr',
'knekt',
'knipa',
'knoge',
'knopp',
'knorr',
'knota',
'knott',
'knubb',
'knuff',
'knyck',
'knyte',
'knäck',
'knäpp',
'koala',
'kobbe',
'kobra',
'kodex',
'kofot',
'kofta',
'kokos',
'kolli',
'kollo',
'kolon',
'kombi',
'kombo',
'komet',
'komma',
'konst',
'kopia',
'koran',
'kossa',
'kotte',
'kraft',
'krage',
'kramp',
'krans',
'krets',
'krita',
'krock',
'krona',
'kropp',
'kruka',
'krull',
'kryss',
'kråka',
'kubik',
'kudde',
'kulle',
'kulör',
'kupol',
'kurir',
'kurva',
'kusin',
'kvarg',
'kvarn',
'kvart',
'kvast',
'kvist',
'kväll',
'kväve',
'kyrka',
'kåsös',
'kägla',
'kälke',
'källa',
'kämpe',
'känga',
'kärna',
'kärra',
'lager',
'lagun',
'lakan',
'lakej',
'lampa',
'laser',
'lasso',
'latex',
'lavin',
'legat',
'lejon',
'lemur',
'lever',
'lider',
'likör',
'lilja',
'limbo',
'limpa',
'linje',
'linne',
'lista',
'liter',
'ljung',
'lobby',
'logga',
'logik',
'lokal',
'loppa',
'loska',
'lotta',
'lotto',
'lotus',
'lucka',
'luder',
'lunch',
'lycka',
'lykta',
'lynne',
'lyrik',
'läcka',
'läder',
'läger',
'länga',
'längd',
'länsa',
'lärka',
'löfte',
'lösen',
'macka',
'madam',
'mager',
'magik',
'magma',
'major',
'makro',
'mamma',
'manet',
'manga',
'mango',
'manus',
'marin',
'match',
'matta',
'maxim',
'medel',
'media',
'melon',
'merit',
'metan',
'meter',
'metod',
'midja',
'mikro',
'milis',
'miljö',
'mimik',
'minne',
'minus',
'minut',
'misse',
'misär',
'mixer',
'mjäll',
'mjölk',
'mobil',
'mocka',
'modem',
'modul',
'modus',
'mogul',
'moped',
'moral',
'morot',
'morsa',
'morse',
'morän',
'mossa',
'motiv',
'motor',
'motto',
'mulla',
'mumie',
'musch',
'musik',
'mygel',
'mygga',
'mylla',
'mynta',
'müsli',
'månad',
'måste',
'mängd',
'märke',
'mässa',
'möbel',
'mögel',
'mölla',
'mössa',
'nacke',
'nagel',
'nalle',
'nasse',
'navel',
'nedan',
'netto',
'ninja',
'nisse',
'njure',
'nolla',
'nomad',
'notis',
'novis',
'nudel',
'nunna',
'nyans',
'nyhet',
'nylle',
'nylon',
'nypon',
'nytta',
'nämnd',
'nästa',
'näste',
'näver',
'oasis',
'ocean',
'ocker',
'odjur',
'offer',
'ogräs',
'ohyra',
'oktav',
'oljud',
'ollon',
'ombud',
'omväg',
'onani',
'opera',
'opium',
'optik',
'orden',
'order',
'oreda',
'organ',
'orgel',
'orgie',
'origo',
'orkan',
'orsak',
'padda',
'padel',
'pajas',
'paket',
'panda',
'panel',
'panik',
'panna',
'pappa',
'parad',
'paria',
'parti',
'party',
'pasta',
'pedal',
'penis',
'penna',
'peruk',
'piano',
'pilot',
'pinne',
'pirat',
'pirog',
'piska',
'pixel',
'pizza',
'pjäxa',
'plack',
'plagg',
'plask',
'plast',
'plats',
'platt',
'platå',
'plikt',
'plock',
'plopp',
'plugg',
'plutt',
'plymå',
'plyte',
'plåga',
'plätt',
'poesi',
'pojke',
'pokal',
'poker',
'polio',
'polis',
'polka',
'potta',
'poäng',
'prakt',
'prata',
'press',
'prick',
'prins',
'progg',
'propp',
'prosa',
'prutt',
'präst',
'psalm',
'psyke',
'pucko',
'pudel',
'puder',
'pulka',
'pulla',
'pulpa',
'pumpa',
'punkt',
'puppa',
'pusta',
'pärla',
'päron',
'pöbel',
'radar',
'radie',
'radio',
'radon',
'raket',
'rally',
'ramsa',
'ravin',
'rebus',
'refug',
'regal',
'regel',
'regim',
'rekyl',
'relik',
'remis',
'remsa',
'renat',
'reson',
'resår',
'revir',
'ribba',
'rista',
'rival',
'robot',
'rodel',
'rodeo',
'roder',
'roman',
'ronda',
'rotel',
'rotor',
'rubin',
'rugby',
'rugge',
'rulle',
'rumpa',
'runda',
'ruter',
'rutin',
'rykte',
'rynka',
'ryska',
'råtta',
'räcke',
'ränta',
'rätta',
'sabel',
'sadel',
'safir',
'saldo',
'saliv',
'salsa',
'salut',
'salva',
'samba',
'sambo',
'satan',
'satin',
'satir',
'satyr',
'sauna',
'scarf',
'scout',
'sedan',
'sedel',
'segel',
'seger',
'sekel',
'semla',
'senap',
'senat',
'sepia',
'serie',
'serum',
'sfinx',
'siden',
'sikte',
'silke',
'sinne',
'sirap',
'siren',
'sista',
'sjuka',
'själv',
'skabb',
'skada',
'skaft',
'skald',
'skall',
'skalp',
'skalv',
'skara',
'skarp',
'skarv',
'skata',
'skatt',
'skede',
'skena',
'skepp',
'skick',
'skida',
'skift',
'skikt',
'skinn',
'skiss',
'skiva',
'skjul',
'skola',
'skolk',
'skott',
'skred',
'skrev',
'skrik',
'skrin',
'skrot',
'skrov',
'skrud',
'skruv',
'skryt',
'skrål',
'skråp',
'skrän',
'skräp',
'skubb',
'skuff',
'skuld',
'skull',
'skult',
'skunk',
'skurk',
'skuta',
'skutt',
'skydd',
'skyle',
'skylt',
'skymf',
'skymt',
'skytt',
'skåle',
'skånk',
'skåra',
'skåre',
'skäck',
'skägg',
'skäkt',
'skäll',
'skälm',
'skämt',
'skänk',
'skära',
'skärm',
'skärp',
'skärv',
'skäva',
'sköka',
'sköld',
'skölp',
'sköra',
'skörd',
'skörl',
'skört',
'sköte',
'slabb',
'sladd',
'slafs',
'slaga',
'slagg',
'slakt',
'slams',
'slana',
'slang',
'slank',
'slant',
'slarv',
'slas	',
'slash',
'slask',
'slatt',
'slejd',
'slejf',
'slejs',
'sleke',
'slice',
'slick',
'slida',
'sling',
'slint',
'slipp',
'slips',
'slira',
'slisk',
'slits',
'slott',
'sluka',
'slump',
'slunk',
'slurk',
'slusk',
'sluss',
'slyna',
'slått',
'släde',
'släkt',
'släng',
'slänt',
'släpa',
'släpp',
'slätt',
'slöja',
'slöjd',
'smide',
'smink',
'smisk',
'smock',
'smula',
'smurf',
'smuts',
'smäck',
'smäll',
'snack',
'snagg',
'snaps',
'snara',
'snark',
'snibb',
'sniff',
'snitt',
'snobb',
'snodd',
'snopp',
'snork',
'snudd',
'snurr',
'snusk',
'snutt',
'snuva',
'snyft',
'snärj',
'snöre',
'socka',
'soffa',
'solur',
'sonar',
'sonat',
'soppa',
'sosse',
'spade',
'spalt',
'spark',
'sparv',
'spasm',
'spene',
'spets',
'spion',
'spjut',
'spole',
'sport',
'spott',
'sprak',
'sprej',
'sprit',
'sprut',
'språk',
'spurt',
'spång',
'spärr',
'spöke',
'stack',
'stake',
'stamp',
'stank',
'starr',
'start',
'staty',
'stege',
'stick',
'stift',
'sting',
'stock',
'stoft',
'stopp',
'stork',
'storm',
'strid',
'stril',
'strof',
'strul',
'strut',
'stryk',
'stråk',
'ström',
'stuga',
'stump',
'stund',
'stunt',
'stupa',
'stuss',
'stuts',
'styck',
'stygn',
'styre',
'stånd',
'stång',
'ställ',
'stänk',
'stöld',
'stört',
'sugga',
'summa',
'svalg',
'svamp',
'svank',
'svans',
'svart',
'svear',
'götar',
'svets',
'svett',
'sving',
'svinn',
'svält',
'sväng',
'svärd',
'svärm',
'syfte',
'syren',
'syrra',
'syrsa',
'sägen',
'sälta',
'sänka',
'söder',
'sörja',
'sötma',
'zebra',
'tablå',
'tango',
'tanke',
'tapet',
'tavla',
'tegel',
'tempo',
'tenor',
'teori',
'tiara',
'tiger',
'tilta',
'timme',
'titel',
'tjack',
'tjafs',
'tjuga',
'tjärn',
'tobak',
'tolva',
'tomat',
'tomte',
'tonår',
'topas',
'toque',
'torka',
'torsk',
'totem',
'trakt',
'trall',
'trams',
'trana',
'trans',
'trapp',
'trasa',
'trask',
'trast',
'tratt',
'trend',
'trick',
'trikå',
'triss',
'troll',
'tromb',
'trosa',
'tross',
'trots',
'trupp',
'tryck',
'tryne',
'träff',
'träsk',
'tröja',
'tröst',
'tugga',
'tumme',
'tumör',
'tunga',
'tunna',
'turbo',
'tusan',
'tusch',
'tving',
'tvist',
'tvång',
'tvätt',
'tweed',
'tycke',
'tyfus',
'tygel',
'tyngd',
'tyska',
'tårta',
'täcke',
'täppa',
'tärna',
'törne',
'törst',
'uggla',
'umami',
'under',
'ungmö',
'union',
'utkik',
'utopi',
'utrop',
'uttal',
'utter',
'utväg',
'vagga',
'vajer',
'valla',
'valör',
'vanna',
'vante',
'vapen',
'varan',
'vecka',
'vegan',
'vemod',
'vespa',
'vidja',
'vifta',
'vigör',
'vilde',
'vilja',
'vilje',
'villa',
'vinge',
'vinst',
'vinyl',
'virke',
'virus',
'visir',
'visit',
'viska',
'visum',
'vodka',
'vokal',
'volym',
'vovve',
'vrede',
'vrist',
'vulva',
'vurpa',
'vårta',
'väbel',
'väder',
'vädur',
'välde',
'välta',
'vända',
'värde',
'värja',
'värld',
'värme',
'väsen',
'väska',
'vätte',
'växel',
'völva',
'yngel',
'yrkan',
'yrsel',
'ytter',
'yxegg',
'åkeri',
'ålder',
'ånger',
'årtal',
'åsikt',
'äckel',
'ägare',
'ängel',
'äpple',
'ölfat',
'ömhet',
'örfil',
'öring',
'öster',
'ankor',
'vråla',
'sjung',
'utrym',
'mörkt',
'sagor',
'suger',
'resan',
'klart',
'huset',
]
